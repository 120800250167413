
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "a40d35da-e2ca-4074-b4b7-86bcb07123a6"
  },
  "_MAPBOX_CONFIG": {
    "accessToken": "pk.eyJ1IjoibWFwLWxvdmVyLXJvYmluIiwiYSI6ImNsbjBjeG1vNzFhdHcya256cXlrbTZocTYifQ.7Q-haGYCotdA3mafNSWZqQ"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
